
import { mapState } from 'vuex'
// import opcoList from '../../assets/src/opco.json'

export default {
  head() {
    return this.$metadata(this.story)
  },
  computed: {
    ...mapState(['global', 'allLocations', 'jobLocations']),
  },
  async asyncData({ $storyblok, store, params, error }) {
    const story = await $storyblok.getStory('careers')
    return {
      story
    }
  },
  data() {
    return {
      story: {}
    }
  },
  methods: {
  }
}
